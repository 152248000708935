import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import gatsbyConfig from '../../../gatsby-config';

// Components
import MainLayout from '../../components/main-layout';
import LegalHeaderSection from './legal-header-section';
import ReactMarkdown from 'react-markdown';
import biuwerLogo from '../../../static/img/biuwer-logo-1000w.png';

const LegalPage =  (props) => {
    const { pageContext } = props,
        { t } = useTranslation(),
        siteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : gatsbyConfig.siteMetadata.siteUrl,
        img = biuwerLogo,
        imgUrl = `${siteUrl}${img}`;

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <Helmet>
                <title>{pageContext.title + '| Biuwer Analytics'}</title>
                <meta property="og:image" content={imgUrl} />
                <meta property="og:description" content={pageContext.description} />
                <meta name="twitter:title" content={pageContext.title+ '| Biuwer Analytics'} />
                <meta name="twitter:description" content={pageContext.description} />
                <meta name="twitter:image" content={imgUrl} />
            </Helmet>

            <LegalHeaderSection t={t} />
            <div className="container">
                <div className="row mb20">
                    <div className="col">
                        <div className="mb20 text-justify">
                        <ReactMarkdown>{pageContext.content}</ReactMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default LegalPage;