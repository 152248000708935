/** @jsx jsx */
import { jsx } from '@emotion/react';
import gatsbyConfig from '../../../gatsby-config';

const LegalHeaderSection =  (props) => {
    const { t } = props;

    return (
        <header
            id="headerSection"
            key="header"
            css={styles.mainSection}
        >
            <div className="container-fluid pt30 mb20">
                <div className="row text-center">
                    <div className="col-12 mb20">
                        <h1 style={{ color: 'white' }}>{t('legal:title')}</h1>
                        <h3 className="mv20" style={{ color: 'white' }}>{t('legal:subTitle')}</h3>
                    </div>
                </div>
            </div>
        </header>
    );
};

const styles = {
    mainSection: {
        paddingTop: gatsbyConfig.siteMetadata.navHeaderHeight,
        background: 'linear-gradient(#2E5CA6, #2E5CA6)'
    }
};

export default LegalHeaderSection;